<template>
	<layouts-cart-complete>
		<div class="card border-0 mt-18">
			<div class="card-body text-center">
				<h3>¡Muchas gracias!</h3>
				<h5 class="mt-2">Recibimos tu pedido correctamente.</h5>
				<div class="d-grid gap-2 mt-7">
					<button class="btn btn-dark" type="button" @click="redirect">Ver mi pedido</button>
				</div>
			</div>
		</div>
	</layouts-cart-complete>
</template>

<script>
	import { ref, onBeforeMount, onBeforeUnmount } from 'vue';
	import { useRoute } from 'vue-router';
	import router from '@/router';
	import composableRedirect from '@/composables/redirect';

	export default {
		setup() {
			const { params: { id } } = useRoute();
			const { redirectBack } = composableRedirect();
			const timeout = ref(null);

			const redirect = () => router.push({ name: 'order.view', params: { id: id } });

			onBeforeMount(() => {
				timeout.value = setTimeout(redirect, 10000);

				//Play sound
				var audio = new Audio('https://partners.wantti.com/partner/sounds/bell-01.mp3');
				audio.play();
			});

			onBeforeUnmount(() => {
				if(timeout.value) clearTimeout(timeout.value);
			});

			if(!id) return redirectBack();

			return { redirect };
		}
	}
</script>